import bg from "./bg.jpg";
import problems from "./set2problems";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Modal, Carousel } from "react-bootstrap";

export default function Set2() {
  //SHUFFLING
  function shuffle() {
    //shuffling problems
    for (var i = problems.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1));
      var temp = problems[i];
      problems[i] = problems[j];
      problems[j] = temp;
    }
    //shuffling options
    for (var l = 0; l < problems.length; l++) {
      for (i = problems[l].options.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        temp = problems[l].options[i];
        problems[l].options[i] = problems[l].options[j];
        problems[l].options[j] = temp;
      }
    }
  }

  //VARIABLES
  const [set2modal1, setset2modal1] = useState(false);
  const [set2modal2, setset2modal2] = useState(false);
  var [minutes, setminutes] = useState("10");
  var [seconds, setseconds] = useState("00");

  //MODALS
  function closeset2modal1() {
    setset2modal1(false);
    clearInterval(window.refreshinterval);
  }
  function closeset2modal2() {
    setset2modal2(false);
  }

  function showset2modal1() {
    shuffle();
    setcurrindex(1);
    setcarouselnextbtn(false);
    setcarouselsubmitbtn(true);
    setset2modal1(true);
  }
  function showset2modal2() {
    closeset2modal1();
    setset2modal2(true);
  }

  //CAROUSEL and BUTTONS
  const [currindex, setcurrindex] = useState(0);
  var [carouselnextbtn, setcarouselnextbtn] = useState(false);
  var [carouselsubmitbtn, setcarouselsubmitbtn] = useState(true);

  const handleslide = (selectedIndex) => {
    setcarouselsubmitbtn(true);
    if (selectedIndex === 4) {
      setcarouselnextbtn(true);
      setcarouselsubmitbtn(false);
    }
    setcurrindex(selectedIndex + 1);
  };

  //CHECKING OPTION CORRECT OR INCORRECT
  function checkingclickedoption(e) {
    if (e.target.parentElement.firstChild.disabled === false) {
      e.target.lastChild.hidden = false;

      if (e.target.lastChild.id === "Correct-option") {
        e.target.id = "Correct";
      } else {
        e.target.id = "Incorrect";
      }

      for (var i = 0; i < 4; i++) {
        if (e.target.htmlFor !== e.target.parentElement.parentElement[i].id) {
          e.target.parentElement.parentElement[i].disabled = true;
        }
      }
    }
  }

  //HOVER
  function hoverin(e) {
    if (e.target.parentElement.firstChild.disabled === false) {
      e.target.className = "form-check-label-hover";
    }
  }
  function hoverout(e) {
    if (e.target.className === "form-check-label-hover") {
      e.target.className = "form-check-label";
    }
  }

  //TIMER
  function starttimer() {
    setminutes("10");
    setseconds("00");
    window.refreshinterval = setInterval(countdown, 1000);
    var time = 600;

    function countdown() {
      minutes = Math.floor(time / 60);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = time % 60;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setminutes(minutes);
      setseconds(seconds);

      if (minutes === "00" && seconds === "00") {
        clearInterval(window.refreshinterval);
        showset2modal2();
      }
      time--;
    }
  }

  function signup() {
    if (!(typeof showsignUpPage === "function")) {
      console.log("testing");
      var showsignUpPage = () => {};
    }

    showsignUpPage();
  }

  return (
    <>
      <span role="button" className="modal_1" onClick={showset2modal1}>
        <img className="modal_1-image" src="bg1set1.png" alt="books" />
      </span>

      <Modal
        show={set2modal1}
        onShow={() => starttimer()}
        onHide={closeset2modal1}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>
            <h5 className="modal-title">Practice Test</h5>
          </Modal.Title>
          <Modal.Title>
            <button
              onClick={closeset2modal1}
              className="btn modal-title close-modal"
            >
              <i className="fa fa-close"></i>
            </button>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="header-bar d-flex justify-content-between">
            <p id="testsingle">Single Choice Question</p>
            <p>
              <i className="fa-solid fa-clock"></i>&ensp;
              <span>
                {minutes}:{seconds}
              </span>
            </p>
            <p>
              <span>0{currindex}</span>/<span>0{problems.length}</span>
            </p>
          </div>

          <Carousel
            prevIcon={<span className="btn carprev outline">Previous</span>}
            nextIcon={
              <span hidden={carouselnextbtn} className="btn carnext notoutline">
                Next
              </span>
            }
            prevLabel={""}
            nextLabel={""}
            indicators={false}
            controls={true}
            variant="dark"
            onSlid={handleslide}
            interval={null}
          >
            {problems.map((problem) => (
              <Carousel.Item>
                <div className="quesbox">
                  <div>{problem.question.questionstatement}</div>
                  <div className="options">
                    <form id={problem.question.formid}>
                      <div className="form-check">
                        <input
                          disabled={false}
                          type="radio"
                          id={problem.options[0].inputid}
                          className="form-check-input"
                          name={problem.inputname}
                          value={problem.options[0].inputvalue}
                        />
                        <label
                          onMouseEnter={(e) => hoverin(e)}
                          onMouseLeave={(e) => hoverout(e)}
                          onClick={(e) => checkingclickedoption(e)}
                          htmlFor={problem.options[0].inputid}
                          className="form-check-label"
                        >
                          A. {problem.options[0].optionstatement}
                          <span
                            hidden
                            id={problem.options[0].labelid + "-option"}
                          >
                            {problem.options[0].labelid}
                          </span>
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          disabled={false}
                          type="radio"
                          id={problem.options[1].inputid}
                          className="form-check-input"
                          name={problem.inputname}
                          value={problem.options[1].inputvalue}
                        />
                        <label
                          onMouseEnter={(e) => hoverin(e)}
                          onMouseLeave={(e) => hoverout(e)}
                          onClick={(e) => checkingclickedoption(e)}
                          htmlFor={problem.options[1].inputid}
                          className="form-check-label"
                        >
                          B. {problem.options[1].optionstatement}
                          <span
                            hidden
                            id={problem.options[1].labelid + "-option"}
                          >
                            {problem.options[1].labelid}
                          </span>
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          disabled={false}
                          type="radio"
                          id={problem.options[2].inputid}
                          className="form-check-input"
                          name={problem.inputname}
                          value={problem.options[2].inputvalue}
                        />
                        <label
                          onMouseEnter={(e) => hoverin(e)}
                          onMouseLeave={(e) => hoverout(e)}
                          onClick={(e) => checkingclickedoption(e)}
                          htmlFor={problem.options[2].inputid}
                          className="form-check-label"
                        >
                          C. {problem.options[2].optionstatement}
                          <span
                            hidden
                            id={problem.options[2].labelid + "-option"}
                          >
                            {problem.options[2].labelid}
                          </span>
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          disabled={false}
                          type="radio"
                          id={problem.options[3].inputid}
                          className="form-check-input"
                          name={problem.inputname}
                          value={problem.options[3].inputvalue}
                        />
                        <label
                          onMouseEnter={(e) => hoverin(e)}
                          onMouseLeave={(e) => hoverout(e)}
                          onClick={(e) => checkingclickedoption(e)}
                          htmlFor={problem.options[3].inputid}
                          className="form-check-label"
                        >
                          D. {problem.options[3].optionstatement}
                          <span
                            hidden
                            id={problem.options[3].labelid + "-option"}
                          >
                            {problem.options[3].labelid}
                          </span>
                        </label>
                      </div>
                    </form>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
          <button
            className="btn carsubmit notoutline"
            onClick={showset2modal2}
            hidden={carouselsubmitbtn}
          >
            Submit
          </button>
        </Modal.Body>
      </Modal>

      <Modal show={set2modal2} onHide={closeset2modal2}>
        <Modal.Body>
          <img src={bg} alt="books" width="470px" height="250px" />
          <br />
          <br />
          <h5 align="center" className="modal-title">
            Thank You for taking the mock test
          </h5>
          <p align="center">
            Subscribe to access the plethora of test sessions Supported by
            Extramarks reports and analytics
          </p>
          <br />
          <div className="container">
            <div className="row">
              <div className="col" align="center">
                <button
                  onClick={closeset2modal2}
                  data-target="#login-modal"
                  data-toggle="modal"
                  className="btn signin login_screen nav-link show-login-modal outline"
                >
                  Sign in
                </button>
              </div>
              <div className="col" align="center">
                <button
                  onClick={() => {
                    closeset2modal2();
                    signup();
                  }}
                  className="btn register sign_up_button signup_screen nav-link show-signup-modal notoutline"
                >
                  Sign up
                </button>
              </div>
            </div>
          </div>
          <br />
        </Modal.Body>
      </Modal>
    </>
  );
}
