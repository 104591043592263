import "./App.css";
import Set1 from "./components/set1";
import Set2 from "./components/set2";

function App1() {
  return (
    <>
      <Set1 />
    </>
  );
}

function App2() {
  return (
    <>
      <Set2 />
    </>
  );
}

export { App1, App2 };
