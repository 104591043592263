var problems = [{
    question: { formid: "set2q1form", questionstatement: "Here two statements are given, one labelled as Assertion ‘A’ and other as Reason ‘R’. You have to examine these two statements carefully and decide if the ‘A’ and ‘R’ are correct individually and if so, whether the reason is the correct explanation of the assertion. Assertion ‘A’: Ethanol is the second member of homologous series of alcohols. Reason ‘R’: Ethanol has two carbon atoms and has the molecular formula C2H5OH" },

    options: [
        { inputid: "set2q1o1", inputvalue: "q1_a", labelid:"Incorrect", optionstatement: "Both A and R are true and R is correct explanation of the assertion" },
        { inputid: "set2q1o2", inputvalue: "q1_b", labelid:"Incorrect", optionstatement: "Both A and R are true, but R is not the correct explanation of the assertion" },
        { inputid: "set2q1o3", inputvalue: "q1_c", labelid:"Correct" , optionstatement: "A is true but R is false." },
        { inputid: "set2q1o4", inputvalue: "q1_d", labelid:"Incorrect" , optionstatement: "A is false but R is true." }],

    inputname: "q1options",
},
{
    question: { formid: "set2q2form", questionstatement: "A hydrocarbon in which the carbon atoms are connected by single bonds only is called" },

    options: [
        { inputid: "set2q2o1", inputvalue: "q2_a", labelid:"Incorrect", optionstatement: "unsaturated" },
        { inputid: "set2q2o2", inputvalue: "q2_b", labelid:"Correct", optionstatement: "saturated" },
        { inputid: "set2q2o3", inputvalue: "q2_c", labelid:"Incorrect", optionstatement: "benzenoid" },
        { inputid: "set2q2o4", inputvalue: "q2_d", labelid:"Incorrect", optionstatement: "non-benzenoid" }],

    inputname: "q2options"
},
{
    question: { formid: "set2q3form", questionstatement: "The mixture of gas used for welding purposes is" },

    options: [
        { inputid: "set2q3o1", inputvalue: "q3_a", labelid:"Incorrect", optionstatement: "ethyne and air" },
        { inputid: "set2q3o2", inputvalue: "q3_b", labelid:"Incorrect", optionstatement: "ethane and air" },
        { inputid: "set2q3o3", inputvalue: "q3_c", labelid:"Correct", optionstatement: "ethyne and oxygen" },
        { inputid: "set2q3o4", inputvalue: "q3_d", labelid:"Incorrect", optionstatement: "ethene and oxygen" }],

    inputname: "q3options"
},
{
    question: { formid: "set2q4form", questionstatement: "Esterification is the name given to" },

    options: [
        { inputid: "set2q4o1", inputvalue: "q4_a", labelid:"Correct", optionstatement: "reaction of an alcohol with a carboxylic acid in presence of sulphuric acid" },
        { inputid: "set2q4o2", inputvalue: "q4_b", labelid:"Incorrect", optionstatement: "formation of a new substance" },
        { inputid: "set2q4o3", inputvalue: "q4_c", labelid:"Incorrect", optionstatement: "a type of addition reaction" },
        { inputid: "set2q4o4", inputvalue: "q4_d", labelid:"Incorrect", optionstatement: "a type of rearrangement reaction" }],

    inputname: "q4options"
},
{
    question: {
        formid: "set2q5form", questionstatement: `In triple covalent bond there is a sharing of` },

    options: [
        { inputid: "q5o1", inputvalue: "q5_a", labelid:"Incorrect", optionstatement: "2 electrons" },
        { inputid: "q5o2", inputvalue: "q5_b", labelid:"Incorrect", optionstatement: "4 electrons" },
        { inputid: "q5o3", inputvalue: "q5_c", labelid:"Correct", optionstatement: "6 electrons" },
        { inputid: "q5o4", inputvalue: "q5_d", labelid:"Incorrect", optionstatement: "8 electrons" }],

    inputname: "q5options"
}]

export default problems