var problems = [{
    question: { formid: "set1q1form", questionstatement: "The value of acceleration due to gravity at the centre of the earth is" },
    
    options: [
        { inputid: "set1q1o1", inputvalue: "q1_a", labelid:"Correct" , optionstatement: "0 ms-2" },
        { inputid: "set1q1o2", inputvalue: "q1_b", labelid:"Incorrect" , optionstatement: "4.90 ms-2" },
        { inputid: "set1q1o3", inputvalue: "q1_c", labelid:"Incorrect" , optionstatement: "9.80 ms-2" },
        { inputid: "set1q1o4", inputvalue: "q1_d", labelid:"Incorrect" , optionstatement: "19.6 ms-2" }],

    inputname: "q1options",
},
{
    question: { formid: "set1q2form", questionstatement: "A convex lens forms a virtual image of an object when the object is" },

    options: [
        { inputid: "set1q2o1", inputvalue: "q2_a", labelid:"Incorrect", optionstatement: "far from the lens" },
        { inputid: "set1q2o2", inputvalue: "q2_b", labelid:"Incorrect", optionstatement: "at centre of curvature" },
        { inputid: "set1q2o3", inputvalue: "q2_c", labelid:"Incorrect", optionstatement: "beyond the centre of curvature" },
        { inputid: "set1q2o4", inputvalue: "q2_d", labelid:"Correct", optionstatement: "in between the focus and the optical centre" }],

    inputname: "q2options"
},
{
    question: { formid: "set1q3form", questionstatement: "Which of these represents 1 amu?" },

    options: [
        { inputid: "set1q3o1", inputvalue: "q3_a", labelid:"Incorrect",  optionstatement: "Mass of a hydrogen atom" },
        { inputid: "set1q3o2", inputvalue: "q3_b", labelid:"Correct", optionstatement: "One-twelfth of the mass of a C – 12 atom" },
        { inputid: "set1q3o3", inputvalue: "q3_c", labelid:"Incorrect", optionstatement: "Mass of O – 16 atom" },
        { inputid: "set1q3o4", inputvalue: "q3_d", labelid:"Incorrect", optionstatement: "Mass of a C – 12 atom" }],

    inputname: "q3options"
},
{
    question: { formid: "set1q4form", questionstatement: "The condition of hexadactyly is a very common congenital birth defect, which does not cause any potential harm. Under which category would you classify this type of variation?" },

    options: [
        { inputid: "set1q4o1", inputvalue: "q4_a", labelid:"Incorrect", optionstatement: "Somatic variation" },
        { inputid: "set1q4o2", inputvalue: "q4_b", labelid:"Incorrect", optionstatement: "Continuous variation" },
        { inputid: "set1q4o3", inputvalue: "q4_c", labelid:"Correct", optionstatement: "Discontinuous variation" },
        { inputid: "set1q4o4", inputvalue: "q4_d", labelid:"Incorrect", optionstatement: "Quantitative variation" }],

    inputname: "q4options"
},
{
    question: {
        formid: "set1q5form", questionstatement: `Below mentioned are some organs of the human body. Identify the correct arrangement of these organs in descending order of probability of developing cancer due to tobacco smoking.
    (a) Lungs(b) Pancreas(c) Oesophagus(d) Cervix(e) Stomach` },

    options: [
        { inputid: "set1q5o1", inputvalue: "q5_a", labelid:"Incorrect", optionstatement: "(a) > (b) > (c) > (d) > (e)" },
        { inputid: "set1q5o2", inputvalue: "q5_b", labelid:"Incorrect", optionstatement: "(a) > (c) > (b) > (d) > (e)" },
        { inputid: "set1q5o3", inputvalue: "q5_c", labelid:"Incorrect", optionstatement: "(a) > (c) > (d) > (e) > (b)" },
        { inputid: "set1q5o4", inputvalue: "q5_d", labelid:"Correct", optionstatement: "(a) > (c) > (b) > (e) > (d)" }],

    inputname: "q5options"
}]

export default problems