import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { App1, App2 } from "./App";
import reportWebVitals from "./reportWebVitals";

const root1 = ReactDOM.createRoot(document.getElementById("mocktest1"));
const root2 = ReactDOM.createRoot(document.getElementById("mocktest2"));

root1.render(<App1 />);

root2.render(<App2 />);

reportWebVitals();
